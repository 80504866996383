import React, { useRef, useMemo } from 'react'
import { contentfulReactComponentsOptions } from '~constants/styles'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import dragonBreathing from '../../templates/dragon-breathing'
import { useIntl } from 'react-intl'
import { Link } from 'gatsby'

const LetThemKnow = props => {
  const { locale } = useIntl()
  const key = locale.toLowerCase()

  return (
    <div id="extra-support">
      <div className='headline-section-centered mt-24 mb-16'>
        <div>
          {props.dragonBreathing.extraSupportTitle}
        </div>
        <hr aria-hidden />
      </div>
      <div className='container mx-auto pb-10'>
        <div className='container mx-auto max-w-3xl px-4 md:px-0'>
          <div className='pb-8'>
            {documentToReactComponents(props?.dragonBreathing.extraSupportCopy.json, contentfulReactComponentsOptions)}
          </div>
          <div className='grid grid-cols-1 lg:grid-cols-2 md:mx-20'>  
            <div className='button button-pink mt-4 mb-0 mx-4'>
            <a href={(key == 'en-us') ? `/${key}/articles/what-are-social-and-emotional-development-milestones/` : `/${key}/videos/from-the-experts-watch-me-learn-watch-me-grow/`} target='_self' rel='noopener noreferrer'>
                {props.dragonBreathing.extraSupportButtonOne} <em className='fas fa-caret-right ml-2' />
              </a>
            </div>
            <div className='button button-pink mt-4 mb-0 mx-4'>
              <a href={`/${key}/topics/special-needs/`} target='_self' rel='noopener noreferrer'>
                {props.dragonBreathing.extraSupportButtonTwo} <em className='fas fa-caret-right ml-2' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LetThemKnow
