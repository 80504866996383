import React from 'react'
import { useIntl } from 'react-intl'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { contentfulReactComponentsOptions } from '~constants/styles'
import dragonBreathing from '../../templates/dragon-breathing'
import Image from 'gatsby-image'

const LeadTheWay = props => {

  return (
    <>
      <div id='lead-the-way' className=''>
        <div className='bg-blue pt-8 pb-10'>
          <div className='headline-section-blue-centered'>
            <div className='text-white headline-3 text-center'>
              {props.dragonBreathing.leadTheWayTitle}
            </div>
            <hr aria-hidden />
          </div>
        </div>
        <div className='container mx-auto max-w-3xl py-8 px-4 md:px-0'>
          {documentToReactComponents(props?.dragonBreathing.leadTheWayCopy.json, contentfulReactComponentsOptions)}
        </div>
        <div className='container mx-auto'>
          <div className='grid grid-cols-1 lg:grid-cols-2'>
            <Image
              fluid={props?.dragonBreathing.leadTheWayImage.fluid}
              imgStyle={{objectFit: 'contain', objectPosition: 'center', width: 'initial', height: 'initial' }}
              className='align-bottom order-2 md:order-1'
              loading='eager'
              alt='N/A'
              aria-hidden
            />
            <div id='leadTheWay-list' className='my-auto order-1 md:order-2 pl-8 md:pl-0'>
              {documentToReactComponents(props?.dragonBreathing.leadTheWayList.json, contentfulReactComponentsOptions)}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LeadTheWay
