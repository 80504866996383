import React from 'react'
import { contentfulReactComponentsOptions } from '~constants/styles'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import dragonBreathing from '../../templates/dragon-breathing'
import { useIntl } from 'react-intl'

const GiveEmotion = props => {
  const { locale } = useIntl()
  const key = locale.toLowerCase()

  return (
    <div id="give-that-emotion">
      <div className='bg-green pt-8 pb-10'>
          <div className='headline-section-green-centered'>
            <div className='text-white headline-3 text-center'>
              {props.dragonBreathing.giveEmotionTitle}
            </div>
            <hr aria-hidden />
          </div>
        </div>
      <div className='container mx-auto lg:pb-8 px-4 md:px-4'>
        <div className='max-w-3xl py-8 mx-auto'>
          <p className=''>{props.dragonBreathing.giveEmotionCopy}</p>
        </div>
        {/* <div className="flex justify-center">
          <video className='rounded-md' width="640" height="640" type="video/mp4" loop autoPlay="autoPlay" playsinline webkit-playsinline muted defaultMuted >
            <source src={`/video/give-emotion-name-${key}.mp4`} ></source>
          </video>
        </div> */}
        <div className='flex justify-center'
          dangerouslySetInnerHTML={{
            __html: 
              `<video style="border-radius:1rem" width="640" height="640" autoplay loop muted playsinline>
                <source src=${`/video/give-emotion-name-${key}.mp4`} type="video/mp4" />
              </video>`,
          }}
        />
      </div>
    </div>
  )
}

export default GiveEmotion
