import React from 'react'
import { useIntl } from 'react-intl'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { contentfulReactComponentsOptions } from '~constants/styles'

const Cta = props => {
  const { locale } = useIntl()
  const key = locale.toLowerCase()
  const ctaClasses = 'md:w-1/2 lg:w-1/4 my-4 px-4'

  return (
    <>
      <div className='cta bg-blue-gray py-4'>
        <div className='container mx-auto grid lg:grid-cols-2 py-4'>
          <h2 className='headline-3 my-auto text-blue mx-6 text-center md:text-inherit mx-10 lg:mx-0 pb-4 lg:pb-0'>
            {props.title}
          </h2>
          <div className='pt-6 md:pt-0 px-4 md:px-0'>
            {documentToReactComponents(props.copy, contentfulReactComponentsOptions)}
          </div>
        </div>
      </div>
    </>
  )
}

export default Cta
