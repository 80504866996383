import React, { useRef, useMemo } from 'react'
import Slider from 'react-slick'
import Image from 'gatsby-image'
import { contentfulReactComponentsOptions } from '~constants/styles'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import dragonBreathing from '../../templates/dragon-breathing'
import { MediaCard } from '~components/shared'

const LetThemKnow = props => {

  const slider = useRef(null)
  const settings = useMemo(() => {
    return {
      arrows: true,
      dots: false,
      infinite: true,
      speed: 400,
      lazyLoad: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      easing: 'ease',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
  }, [])

  return (
    <div id="let-them-know">
      <div className='headline-section-centered mt-16 lg:mt-24 mb-16'>
        <div>
          {props.dragonBreathing.understandTitle}
        </div>
        <hr aria-hidden />
      </div>
      <div className='container mx-auto lg:pb-24'>
        <div className='container mx-auto max-w-3xl px-4 md:px-0'>
          <div className='pb-8'>{props.dragonBreathing.understandCopy}</div>
        </div>
        <Slider ref={slider} {...settings} className='px-2 md:px-0'>
           <div className='px-1 h-full'>
            <MediaCard image={'/images/dragon/understand-1.png'} title={props.dragonBreathing.understandOne} caption={null} url={null} type={null} displayStyle={'card'}/>
            <br/>
          </div>
          <div className='px-1 min-h-full	'>
            <MediaCard image={'/images/dragon/understand-2.png'} title={props.dragonBreathing.understandTwo} caption={null} url={null} type={null} displayStyle={'card'}/>
          </div>
          <div className='px-1 h-full'>
            <MediaCard image={'/images/dragon/understand-3.png'} title={props.dragonBreathing.understandThree} caption={null} url={null} type={null} displayStyle={'card'}/>
          </div>
          <div className='px-1 h-full'>
            <MediaCard image={'/images/dragon/understand-4.png'} title={props.dragonBreathing.understandFour} caption={null} url={null} type={null} displayStyle={'card'}/>
          </div>
          <div className='px-1 h-full'>
            <MediaCard image={'/images/dragon/understand-5.png'} title={props.dragonBreathing.understandFive} caption={null} url={null} type={null} displayStyle={'card'}/>
          </div>
        </Slider>
      </div>
      <div className='mobile-carousel-button container mx-auto flex justify-center items-center pt-4 pb-8 lg:pb-0 lg:my-8'>
        <div>
          <button
            onClick={() => slider.current.slickPrev()}
            className='icon-large bg-green border border-green hover:bg-white text-white group'
          >
           <em className='fas fa-caret-left group-hover:text-green' />
          </button>
          <button
            onClick={() => slider.current.slickNext()}
            className='icon-large ml-2 bg-green border border-green hover:bg-white text-white group'
          >
            <em className='fas fa-caret-right group-hover:text-green' />
          </button>
        </div>
      </div>
    </div>
  )
}

export default LetThemKnow
