import React, { useRef, useMemo } from 'react'
import Slider from 'react-slick'
import Image from 'gatsby-image'
import { contentfulReactComponentsOptions } from '~constants/styles'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { MediaCard } from '~components/shared'
import dragonBreathing from '../../templates/dragon-breathing'

const CalmingHelps = props => {

  const slider = useRef(null)
  const settings = useMemo(() => {
    return {
      arrows: false,
      dots: false,
      infinite: false,
      speed: 400,
      lazyLoad: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      easing: 'ease',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
  }, [])

  return (
    <div id="calmingHelps">
      <div className='bg-royal-purple pt-8 pb-10'>
          <div className='headline-section-royal-purple-centered'>
            <div className='text-white headline-3 text-center'>
              {props.dragonBreathing.calmingHelpsTitle}
            </div>
            <hr aria-hidden />
          </div>
        </div>
      <div className='container mx-auto pb-8'>
        <div className='max-w-3xl py-8 mx-auto px-4 md:px-0'>
          <p className=''>{props.dragonBreathing.calmingHelpsSlug}</p>
        </div>
        <Slider ref={slider} {...settings} className='container mx-auto px-2 lg:px-10'>
          <div className='px-1 lg:w-1/3'>
            <MediaCard image={'/images/dragon/calming-1.png'} title={props.dragonBreathing.calmingHelpsOne} caption={null} url={null} type={null} displayStyle={'card'}/>
            <br />
          </div>
          <div className='px-1 lg:w-1/3'>
            <MediaCard image={'/images/dragon/calming-2.png'} title={props.dragonBreathing.calmingHelpsTwo} caption={null} url={null} type={null} displayStyle={'card'}/>
            <br />
          </div>
          <div className='px-1 lg:w-1/3'>
            <MediaCard image={'/images/dragon/calming-3.png'} title={props.dragonBreathing.calmingHelpsThree} caption={null} url={null} type={null} displayStyle={'card'}/>
            <br />
          </div>
        </Slider>
        <div className='mobile-carousel-button container mx-auto flex justify-center items-center pb-8 md:pb-0 lg:my-8'>
        <div>
          <button
            onClick={() => slider.current.slickPrev()}
            className='icon-large bg-green border border-green hover:bg-white text-white group'
          >
           <em className='fas fa-caret-left group-hover:text-green' />
          </button>
          <button
            onClick={() => slider.current.slickNext()}
            className='icon-large ml-2 bg-green border border-green hover:bg-white text-white group'
          >
            <em className='fas fa-caret-right group-hover:text-green' />
          </button>
        </div>
      </div>
      </div>
    </div>
  )
}

export default CalmingHelps
