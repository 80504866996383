import React from 'react'
import { useIntl } from 'react-intl'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { contentfulReactComponentsOptions } from '~constants/styles'

const LifetimeBenefits = props => {
  const { locale } = useIntl()
  const key = locale.toLowerCase()
  const ctaClasses = 'md:w-1/2 lg:w-1/4 my-4 px-4'

  return (
    <>
      <div id="lifetime-benefits" className='bg-off-white pt-1'>
        <div className='headline-section-centered-gray mt-16 mb-16'>
          <div className='bg-off-white headline-3 my-auto text-royal-purple text-center'>
            {props.dragonBreathing.lifetimeBenefitsTitle}
          </div>
          <hr aria-hidden />
        </div>
        <div className='container mx-auto py-4 px-4 md:px-0'>
          <div className='max-w-3xl mx-auto'>
            <div id='lifetime-benefits-copy'>
              {documentToReactComponents(props?.dragonBreathing.lifetimeBenefitsCopy.json, contentfulReactComponentsOptions)}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LifetimeBenefits
