import React, { Component, useRef, useMemo, useEffect, useState } from 'react'
import dragonBreathing from '../../templates/dragon-breathing'
import { Player, PlaybackRate } from 'video-react';
import cx from 'classnames'

export default class PracticeDragonBreathing extends Component {
  constructor(props, context) {
    super(props, context);

    const english = [
      "Take a deep breath",
      "That's it",
      "Almost there",
      "Let it out",
      "You did it!"
    ]

    const spanish = [
      "Respira profundo",
      "¡Eso es!",
      "¡Ya casí!",
      "¡Eso es todo!",
      "¡Lo lograste! "
    ]

    this.state = {
      source: '/video/640x640.mp4',
      messages: [],
      english: english,
      spanish: spanish,
      status: '',
      videoCompleted: false,
      barCompleted: false,
      playbackRate: 0.4,
      title: {},
      active: false,
    };

    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.holdEvent = this.holdEvent.bind(this);
    this.releaseEvent = this.releaseEvent.bind(this);
    this.activeStatus = this.activeStatus.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.touchStart = this.touchStart.bind(this);
    this.touchEnd = this.touchEnd.bind(this);
  }

  componentDidMount() {
    this.setLanguage();
    // subscribe state change
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    this.button.addEventListener('touchstart', this.touchStart, {passive: false})
    this.button.addEventListener('touchend', this.touchEnd, {passive: false})
  }

  touchStart(evt) {    
    evt.stopPropagation();
    evt.preventDefault();
    console.log(evt)
    this.holdEvent()
  }

  touchEnd(evt) {    
    evt.stopPropagation();
    evt.preventDefault();
    console.log(evt)
    this.releaseEvent()
  }

  handleStateChange(state, prevState) {
    // copy player state to this component's state
    this.setState({
      player: state,
      currentTime: state.currentTime,
    });
  }

  play() {
    this.player.play();
  }

  pause() {
    this.player.pause();
  }

  setLanguage() {
   if (this.props.language == 'en-US') {
     this.setState({
       ...this.state,
       messages: this.state.english,
       status: this.state.english[0]
     })
   } else {
     this.setState({
       ...this.state,
       messages: this.state.spanish,
       status: this.state.spanish[0]
     })
   }
  }

  handleEvent = (event) => {
    if (event.type === "mousedown") {
      this.setState({ active: true});
      this.holdEvent()
    } else {
      this.setState({ active: false});
      this.releaseEvent()
    }
   }

  holdEvent() {
    let time = this.player.getState();
    this.setState({
      ...this.state,
      active: true,
      videoCompleted: false,
      barCompleted: false,
      status: this.state.messages[0],
      playbackRate: 0.4
    })
    this.setPlayBackRate();
    this.play();
    this.interval = setInterval(() => {
      this.activeStatus()
    }, 100)
  }

  releaseEvent() {
    let time = this.player.getState();
    if (!this.state.barCompleted) {
      this.setState({
        active: false,
        status: this.state.messages[0],
        playbackRate: 0.4
      })
      this.player.replay(4)
      clearInterval(this.interval)
      this.player.pause();
    }
  }

  setPlayBackRate() {
    this.player.playbackRate = this.state.playbackRate
  }

  activeStatus() {
    this.setPlayBackRate();
    if (this.state.player.currentTime > 1) {
      this.setState({
        status: this.state.messages[0]
      })
    }
    if (Math.round(this.state.player.currentTime * 100000) > 42000) {
      this.setState({
        status: this.state.messages[1]
      })
    } 
    if (Math.round(this.state.player.currentTime * 100000) > 84000) {
      this.setState({
        status: this.state.messages[2]
      })
    } 
    if (Math.round(this.state.player.currentTime * 100000) > 127501) {
      this.setState({
        status: this.state.messages[3],
        barCompleted: true,
        playbackRate: 1
      })
    }
    if (Math.round(this.state.player.currentTime * 100000) > 366497) {
      this.setState({
        status: this.state.messages[4]
      })
    }
  }

  render() {
    return (
      <div>
        <div className='headline-section-centered mt-16 lg:mt-24 mb-16'>
            <h1>{this.props.language == 'en-US' ? 'Practice Dragon Breathing to Create Calm' : 'Practica la respiración del dragón para calmarlos'}</h1>
          <hr aria-hidden />
        </div>
        <div className='container mx-auto pb-8 max-w-3xl'>
          <p className='body-4 pb-10 px-4 md:px-0 md:mx-10 lg:mx-0 '>
            {this.props.language == 'en-US' ? 'Young kids can get angry fast. Try doing this fun and simple breathing game together, to help everyone stay calm.' : 'Los niños pueden enojarse rápido. Prueba esta forma divertida y sencilla para ayudarlos a mantener la calma.'} 
          </p>
          <div className='flex justify-center flex-row px-4 md:px-0'>
            <div id='progress-bar' >
              <span className='w-full'>
                <span className={((this.state.active && this.state.currentTime > 0.1) || this.state.barCompleted) ? 'fill' : null}></span>
              </span>
            </div>
            <div className='rounded-2xl bg-blue max-w-fit-content'>
              <Player
                className=''
                ref={player => {
                  this.player = player;
                }}
                playsInline={true}
                preload={`auto`}
                poster='/images/dragon/breathing-poster.png'
                muted={true}
              >
                <source src={this.state.source} />
              </Player>
              <div className=''>
                {/* <h1 className='text-white text-center headline-3 pt-4 pb-6 rounded-b-2xl'>{(this.state.status == '') ? this.state.messages[0] : this.state.status}</h1> */}
                <h1 className='text-white text-center headline-3 pt-4 pb-6 rounded-b-2xl'>{this.state.status}</h1>
              </div>
            </div>
          </div>
        
          <div className="pl-1 py-4 mx-auto d-block text-center">
            <button ref={evt => this.button = evt} onMouseDown={this.handleEvent} onMouseUp={this.handleEvent} onTouchStart={this.touch} onTouchCancel={this.handleEvent} className='button button-pink text-white hover:text-pink font-bold py-3 px-3 flex-row'>
            Click &amp; Hold &nbsp;
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.2392 32C21.217 32 21.1947 32 21.1725 31.9983C20.6897 31.9704 20.2771 31.6517 20.1214 31.1868L15.4411 16.6749C15.3247 16.3074 15.388 15.9051 15.6089 15.5951C15.8314 15.2834 16.1892 15.0971 16.5658 15.0953H16.6257C16.7096 15.104 16.8311 15.1197 16.9373 15.1545L31.1955 19.9141C31.6594 20.0743 31.9726 20.4922 31.9983 20.9833C32.0257 21.4779 31.757 21.9342 31.3136 22.1449L28.174 23.6113L31.6063 27.103C32.0634 27.5714 32.0617 28.3325 31.6029 28.8009L28.857 31.5978C28.6345 31.8259 28.3349 31.953 28.0165 31.953C27.6998 31.953 27.4037 31.8276 27.1811 31.5995L23.7522 28.1095L22.3143 31.2982C22.1157 31.7319 21.698 32 21.2375 32H21.2392ZM23.2969 25.5252L28.0182 30.33L30.3566 27.9476L25.6353 23.1445L30.0211 21.0965L17.0143 16.755L21.2837 29.9886L23.2952 25.5234L23.2969 25.5252Z" fill="currentColor"/>
                <path d="M13.9706 17.2495C15.7084 17.2495 17.1171 15.8164 17.1171 14.0486C17.1171 12.2809 15.7084 10.8478 13.9706 10.8478C12.2329 10.8478 10.8242 12.2809 10.8242 14.0486C10.8242 15.8164 12.2329 17.2495 13.9706 17.2495Z" fill="currentColor"/>
                <path d="M13.8199 5.88452C9.38791 5.88452 5.78442 9.55037 5.78442 14.0591C5.78442 18.5678 9.38791 22.2337 13.8199 22.2337C13.8268 22.2337 13.8336 22.2337 13.8422 22.2337V20.4347C13.8422 20.4347 13.8268 20.4347 13.8199 20.4347C10.3637 20.4347 7.55107 17.5734 7.55107 14.0574C7.55107 10.5413 10.3637 7.68 13.8199 7.68C16.802 7.68 19.3031 9.8116 19.9347 12.6555H21.7373C21.0817 8.81372 17.7812 5.88626 13.8217 5.88626L13.8199 5.88452Z" fill="currentColor"/>
                <path d="M13.8181 10.8321C12.0668 10.8321 10.6494 12.2758 10.6494 14.0556C10.6494 15.8354 12.0446 17.2477 13.7719 17.2739L13.385 16.8176C12.464 15.7239 12.3698 14.3185 13.1727 13.6185L13.6914 13.1657C13.7393 13.1239 13.7907 13.0856 13.8421 13.0507V12.6537H16.6735C16.1616 11.5757 15.0746 10.8303 13.8181 10.8303V10.8321Z" fill="currentColor"/>
                <path d="M13.8422 26.3158C13.8422 26.3158 13.8268 26.3158 13.8199 26.3158C7.17616 26.3158 1.76836 20.8161 1.76836 14.0556C1.76836 7.29513 7.17274 1.79722 13.8199 1.79722C19.9981 1.79722 25.0892 6.55499 25.7756 12.6555H27.5577C26.8627 5.56234 20.9721 0 13.8182 0C6.2004 0 0 6.3077 0 14.0574C0 21.807 6.2004 28.1147 13.8182 28.1147C13.8268 28.1147 13.8336 28.1147 13.8422 28.1147V26.3175V26.3158Z" fill="currentColor"/>
              </svg>
            </button> 
          </div>
        </div>
      </div>
    );
  }
}
