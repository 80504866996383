import React, { useRef, useMemo } from 'react'
import Slider from 'react-slick'
import Image from 'gatsby-image'
import { contentfulReactComponentsOptions } from '~constants/styles'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { MediaCard } from '~components/shared'
import dragonBreathing from '../../templates/dragon-breathing'

const GoodForAllAges = props => {

  const slider = useRef(null)
  const settings = useMemo(() => {
    return {
      arrows: true,
      dots: false,
      infinite: true,
      speed: 400,
      lazyLoad: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      easing: 'ease',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
  }, [])

  return (
    <div id="good-for-all-ages">
      <div className='headline-section-centered mt-16 lg:mt-24 mb-16'>
        <div>
          {props.dragonBreathing.goodForAllTitle}
        </div>
        <hr aria-hidden />
      </div>
      <div className='container mx-auto pb-8'>
        <Slider ref={slider} {...settings} className='container mx-auto flex px-2 md:px-0'>
          <div className='px-1'>
            <MediaCard image={'/images/dragon/good-for-all-1.png'} title={props.dragonBreathing.goodForAllCardOne} caption={null} url={null} type={null} displayStyle={'card'}/>
            <br />
          </div>
          <div className='px-1'>
            <MediaCard image={'/images/dragon/good-for-all-2.png'} title={props.dragonBreathing.goodForAllCardTwo} caption={null} url={null} type={null} displayStyle={'card'}/>
          </div>
          <div className='px-1'>
            <MediaCard image={'/images/dragon/good-for-all-3.png'} title={props.dragonBreathing.goodForAllCardThree} caption={null} url={null} type={null} displayStyle={'card'}/>
          </div>
          <div className='px-1'>
            <MediaCard image={'/images/dragon/good-for-all-4.png'} title={props.dragonBreathing.goodForAllCardFour} caption={null} url={null} type={null} displayStyle={'card'}/>
          </div>
          <div className='px-1'>
            <MediaCard image={'/images/dragon/good-for-all-5.png'} title={props.dragonBreathing.goodForAllCardFive} caption={null} url={null} type={null} displayStyle={'card'}/>
          </div>
        </Slider>
      </div>
      <div className='mobile-carousel-button container mx-auto flex justify-center items-center pb-8 lg:pb-0 lg:my-8'>
        <div>
          <button
            onClick={() => slider.current.slickPrev()}
            className='icon-large bg-green border border-green hover:bg-white text-white group'
          >
           <em className='fas fa-caret-left group-hover:text-green' />
          </button>
          <button
            onClick={() => slider.current.slickNext()}
            className='icon-large ml-2 bg-green border border-green hover:bg-white text-white group'
          >
            <em className='fas fa-caret-right group-hover:text-green' />
          </button>
        </div>
      </div>
    </div>
  )
}

export default GoodForAllAges
