import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import { FormattedMessage } from 'react-intl'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Button } from '~components/shared'

import Layout from '~components/layout'
import SEO from '~components/seo'
import Cta from '~components/dragon/cta'
import LeadTheWay from '~components/dragon/lead-the-way'
import GoodForAllAges from '~components/dragon/good-for-all-ages'
import CalmingHelps from '~components/dragon/calming-helps'
import LifetimeBenefits from '../components/dragon/lifetime-benefits'
import LetThemKnow from '../components/dragon/let-them-know'
import GiveEmotion from '../components/dragon/give-emotion-name'
import ExtraSupport from '../components/dragon/extra-support'
import PracticeDragonBreathing from '../components/dragon/practice-dragon-breathing'

const dragonBreathing = props => {
  const { section, locale } = props.pageContext
  const { location } = props
  const dragonBreathing = props.data.contentfulDragonBreathing
  const { settings } = props.data
  const { californiaStateSiteLink } = settings

  let title = ''
  let heroHeadline = ''
  let heroSubline = ''
  let heroImage = ''
  let heroNavCard1 = {}
  let heroNavCard2 = {}
  let heroNavCard3 = {}
  let localLookup = {}
  let partnersDescription = ''
  let partnersLogos = []
  let support = {
    title: dragonBreathing.supportCtaTitle,
    copy: dragonBreathing.supportCtaCopy.json
  }
  let leadTheWayImage
  let lifetimeBenefits = {
    title: dragonBreathing.practiceCtaTitle,
    copy: dragonBreathing.practiceCtaCopy.json
  }

  let newDocument = {
    ...dragonBreathing
  }

  const navCardClasses = 'md:w-1/2 lg:w-1/4 my-4 md:px-4'

  if (newDocument.heroImage.fluid) {
    heroImage = {
      fluid: { ...newDocument.heroImage.fluid },
      style: {
      }
    }
  }

  if (newDocument.leadTheWayImage.fluid) {
    leadTheWayImage = {
      fluid: { ...newDocument.leadTheWayImage.fluid },
      style: {
      }
    }
  }

  return (
    <Layout locale={locale} location={location} section={section}>
      <SEO lang={locale} title={dragonBreathing?.metaTitle || dragonBreathing.title} description={dragonBreathing?.metaDescription} />
      <div className='grid gap-2 grid-cols-1 lg:grid-cols-2 grid-rows-1'>
          <div className='max-w-lg my-auto mx-auto order-2 lg:order-1 pt-4 md:pt-0'>
            <div className='flex justify-end flex-col px-4 md:px-inherit'>
              <h1 className='headline-1 text-green py-4'>{dragonBreathing.heroHeadline}</h1>
              <h2 className='text-3xl mb-4'>{dragonBreathing.heroSubline}</h2>
              <p className='body-2 max-w-2xl'>{dragonBreathing.heroCopy}</p>
            </div>
          </div>
          <div id="dragon-hero" className='order-1 lg:order-2'>
            <Image
              fluid={heroImage.fluid}
              imgStyle={{objectFit: 'contain', objectPosition: 'center', width: 'initial', height: 'initial' }}
              className='align-bottom'
              loading='eager'
              alt='N/A'
              aria-hidden
            />
          </div>
        </div>
      <div className='relative container mx-auto px-4 md:px-inherit'>
        <div className='container mx-auto pt-20 pb-14'>
          <div className='border-4 border-blue'>
            <div className="grid grid-cols-1 lg:grid-cols-4 lg:grid-rows-1">
              <div className='relative order-3 lg:order-1 pt-4 lg:pt-0'>
                <img src='/images/dragon/video-cta.png' className='lg:absolute bottom-0 h-100 mx-auto'></img>
              </div>
              <h2 className='headline-3 my-auto text-blue order-1 lg:order-2 lg:pl-8 px-2 py-4 lg:py-inherit lg:col-span-2'>{dragonBreathing.ctaTitle}</h2>
              <div className='container flex justify-center order-2 lg:order-3'>
                <a className='my-auto' href={(locale == 'en-US') ? `/en-us/videos/the-dragon-song-calming-kids/` : `/es-mx/videos/the-dragon-song-calming-kids/`} target='_blank' rel='noopener noreferrer'>
                  <Button type='button' className='my-auto py-4 md:py-0'>
                    {dragonBreathing.ctaLink}
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PracticeDragonBreathing language={locale} />
      <LeadTheWay dragonBreathing={dragonBreathing} />
      <Cta {...support} />
      <GoodForAllAges dragonBreathing={dragonBreathing}/>
      <CalmingHelps dragonBreathing={dragonBreathing} />
      <LifetimeBenefits dragonBreathing={dragonBreathing} />
      <Cta {...lifetimeBenefits} />
      <LetThemKnow dragonBreathing={dragonBreathing} />
      <GiveEmotion dragonBreathing={dragonBreathing} />
      <ExtraSupport dragonBreathing={dragonBreathing} />
    </Layout>
  )
}

export default dragonBreathing

export const dragonBreathingPageQuery = graphql`
  query dragonBreathingPageQuery($locale: String!) {
    contentfulDragonBreathing(node_locale: { eq: $locale }) {
      title
      slug
      metaTitle
      metaDescription
      heroHeadline
      heroSubline
      heroCopy
      heroImage {
        fluid(maxWidth: 720, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      ctaTitle
      ctaLink
      ctaImage {
        fluid(maxWidth: 1440, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      supportCtaTitle
      supportCtaCopy {
        json
      }
      leadTheWayTitle
      leadTheWayCopy {
        json
      }
      leadTheWayList {
        json
      }
      leadTheWayImage {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      bigFeelingsTitle
      bigFeelingsCopy
      goodForAllTitle
      goodForAllCardOne
      goodForAllCardTwo
      goodForAllCardThree
      goodForAllCardFour
      goodForAllCardFive
      calmingHelpsTitle
      calmingHelpsSlug
      calmingHelpsOne
      calmingHelpsTwo
      calmingHelpsThree
      lifetimeBenefitsTitle
      lifetimeBenefitsCopy {
        json
      }
      practiceCtaTitle
      practiceCtaCopy {
        json
      }
      understandTitle
      understandCopy
      understandOne
      understandTwo
      understandThree
      understandFour
      understandFive
      giveEmotionTitle
      giveEmotionCopy
      extraSupportTitle
      extraSupportCopy {
        json
      }
      extraSupportButtonOne
      extraSupportButtonTwo
    }
    allContentfulCountyDirectory(filter: { node_locale: { eq: $locale } }, sort: { order: ASC, fields: county }) {
      nodes {
        id
        county
        phone
        slug
        title
        website
        zipCodes
        address {
          address
        }
        summary {
          json
        }
      }
    }
    settings: contentfulSettings(title: { eq: "Global Settings" }) {
      californiaStateSiteLink
    }
  }
`
